export default {
  attemptToReconnect: 'Pokušaj ponovnog povezivanja...',
  betFor: 'na',
  bettingTimeLeft: 'Preostalo vreme za klađenje',
  coldField: 'Cold field',
  connected: 'Povezan',
  connecting: 'Povezivanje',
  disconnected: 'Nepovezan',
  eighthFinals: 'Osmina finala',
  finalist: 'Finalista',
  finals: 'Finale',
  gameDeactivated: 'Igra je deaktivirana!',
  headToHead: 'Međusobni skor',
  hotField: 'Hot field',
  knockoutStage: 'Nokaut faza',
  latestRaces: 'Najnovije trke',
  loading: 'Učitavanje',
  outrights: 'Konačni rezultati',
  problemInCommunication: 'Postoji problem u komunikaciji sa serverom!',
  problemWithGame: 'Postoji problem sa igrom, sačekajte!',
  productName: 'Virtuelne Drag Trke',
  qualifiesTo: 'Kvalifikuje se za',
  quarterFinalist: 'Četvrtfinalista',
  quarterFinals: 'Četvrtfinale',
  raceNo: 'Trka br.',
  raceNow: 'trenutno',
  racerOut: 'izbačen',
  raceSchedule: 'Raspored trke',
  raceTime: 'Vreme trke',
  raceWinner: 'Pobednik trke',
  reconnected: 'Ponovo povezan',
  results: 'Rezultati',
  round: 'Runda',
  roundIn: 'u',
  roundNumber: 'Broj runde',
  roundStartsIn: 'Runda {{roundNumber}} počinje za',
  semiFinalist: 'Polufinalista',
  semiFinals: 'Polufinale',
  statistics: 'statistika',
  tournament: 'Turnir',
  tournamentNo: 'Turnir br.',
  tournamentOutrights: 'Konačni rezultati turnira',
  tournamentWinner: 'Pobednik turnira',
  upcoming: 'Predstojeća',
  waitingForTheRace: 'Čeka se početak trke',
  winner: 'Pobednik',
  winnerShort: 'p',
};
